define([
    "Backbone",
    'registry',
    "views/apps/links",
    "views/apps/stats",
    "views/leagues",
    "views/liveleads",
    "views/banking",
    "views/domains",
    "views/domainParking",
    "views/support",
    "views/trends",
    "views/vertical-os-carrier",
    "views/settings",
    "views/earnings",
    "views/links",
    "views/home",
    "views/advertiserSubscriptions",
    "views/subscriptions",
    "views/offerMarketplace",
    "views/cpaMarketplace",
    "views/pushStats",
    "views/pushCollection",
    "views/leaderboard",
    "views/inbox",
    "views/pidKeywordTool",
    "views/offerMarket/offerMarket",
    "views/offerMarket/advertiserRequestDetails",
    "views/offerMarket/publisherRequestDetails",
    "views/requests/requests",
    "views/subsourceAnalysis",
    'views/keyentry',
    'views/academy',
    'views/academyPost',
    'views/unsubscribe',
    'views/tos'
], function(
    Backbone,
    registry,
    appsLinksView,
    appsStatsView,
    leaguesView,
    liveLeadsView,
    bankingView,
    domainsView,
    domainParkingView,
    supportView,
    trendsView,
    inspireView,
    profileView,
    earningsView,
    linksView,
    homeView,
    advertiserSubscriptionsView,
    subscriptionsView,
    offerMarketplaceView,
    cpaMarketplaceView,
    pushStatsView,
    pushCollectionView,
    leaderboardView,
    inboxView,
    pidKeywordTool,
    offerMarketView,
    advertiserRequestDetailsView,
    publisherRequestDetailsView,
    requestsView,
    subsourceAnalysisView,
    loginView,
    academyView,
    academyPostView,
    unsubscribeView,
    tosView
) {
    var MainRouter = Backbone.Router.extend({
        currentView: null,

        publicRouteHandlers: [
            'academy',
            'publicOfferMarket',
            'publicAdvertiserRequestDetails',
            'publicPublisherRequestDetails',
            'unsubscribe',
            'terms'
        ],

        routes: {
            "": "marketplace",
            "apps/links": "appsLinks",
            "apps/stats": "appsStats",
            leagues: "dashboard",
            'offerRequests(/?*queryString)': 'offerMarket',
            'offerRequests/advertiserRequest/:id': 'advertiserRequestDetails',
            'offerRequests/publisherRequest/:id': 'publisherRequestDetails',
            'offerRequests/myRequests': 'requests',
            'offerRequests/main/advertiserRequest/:id': 'publicAdvertiserRequestDetails',
            'offerRequests/main/publisherRequest/:id': 'publicPublisherRequestDetails',
            'offerRequests/main(?*queryString)': 'publicOfferMarket',
            liveLeads: "liveLeads",
            'banking(?*queryString)': "banking",
            domains: "domains",
            domainParking: "domainParking",
            support: "support",
            trends: "trends",
            inspire: "inspire",
            profile: "profile",
            earnings: "earnings",
            "links(/:var1)(/:var2)": "links",
            snapshot: "snapshot",
            advertiserSubscriptions: "advertiserSubscriptions",
            subscriptions: "subscriptions",
            marketplace: "marketplace",
            cpamarketplace: "cpamarketplace",
            leaderboard: "leaderboard",
            push: "push",
            pushCollection: "pushCollection",
            inbox: "inbox",
            pidKeywordTool: "pidKeywordTool",
            subsourceAnalysis: 'subsourceAnalysis',
            login: 'login',
            'academy(/:subpage)(/:var1)': 'academy',
            'unsubscribe/:list/:id-:hash': 'unsubscribe',
            'terms': 'terms'
            //"*path": "marketplace"
        },


        initialize: function() {
            this.bind("route", this._pageView);
        },

        isPublicHandler: function(name) {
            return _.contains(this.publicRouteHandlers, name);
        },

        execute: function (callback, args, name) {
            var self = this;


            //if has app.user.hasdata
            if (localStorage.apiToken !== undefined) {
                $.ajax({
                    url: API_URL + "data/user-session.php",
                    crossDomain: true,
                    headers: {
                        'X_AFFLOW_USER_API_TOKEN': localStorage.apiToken,
                        'X_AFFLOW_CLIENT_VERSION': registry.versionStr
                    }
                })
                    .done(function (userData) {
                        registry.user.token = userData.api_key;
                        //registry.user.email = userData.email;
                        registry.userWorker.query();



                        $.when(registry.hasUserData).
                        then(function() {
                            var route = Backbone.history.getFragment();

                            queryString = window.location.href.split("?");
                            if (queryString[1] !== undefined) {
                                var params = new URLSearchParams(queryString[1]);
                                if(params.has('pid') && params.get('pid') != registry.user.id) {
                                    localStorage.removeItem('apiToken');
                                    location.reload();
                                }
                            }

                            if(name === 'login') {
                                Backbone.history.navigate('/', {trigger: true});
                                return;

                            }

                            loginView._showUI();
                            $('.visitorView').hide();
                            $('.userView').show();

                            if (registry.user.profileComplete === false && Backbone.history.getFragment() !== 'profile') {
                                alert('Please fill your profile details in order to be able to use the site.');
                                Backbone.history.navigate('/profile', {trigger: true});
                                return false;
                            }

                            if (callback) callback.apply(self, args);
                        });

                    })
                    .fail(function () {
                        localStorage.removeItem('apiToken');

                        if(self.isPublicHandler(name)) {
                            loginView._showUI();
                            if (callback) callback.apply(self, args);
                        } else {
                            loginView._showLogin(Backbone.history.getFragment());
                        }
                    });
            } else { // localStorage.apiToken === undefined
                if(self.isPublicHandler(name)) {
                    loginView._showUI();

                    if (callback) callback.apply(self, args);
                } else {
                    loginView._showLogin(Backbone.history.getFragment());
                }
            }
        },




        _pageView: function() {
            var path = Backbone.history.getFragment();
            ga("send", "pageview", { page: "/" + path });
        },

        appsLinks: function() {
            const allowedIds = [10, 13, 131, 1703, 3150, 5831, 16856, 20186, 24072];
            if (!allowedIds.includes(+registry.user.id)) {
                Backbone.history.navigate('/', {trigger: true});
            }
            var self = this;
            $.when(this.sleepCurrent()).then(function() {
                $(".page-title").html("Apps Links");
                document.title = "Monetizer - Apps - Links";
                $(".title-icon").attr("class", "title-icon fa fa-link");
                self.setCurrentAndWake(appsLinksView);
            });
        },

        appsStats: function() {
            const allowedIds = [10, 13, 131, 1703, 3150, 5831, 16856, 20186, 24072];
            if (!allowedIds.includes(+registry.user.id)) {
                Backbone.history.navigate('/', {trigger: true});
            }
            var self = this;
            $.when(this.sleepCurrent()).then(function() {
                $(".page-title").html("Apps Stats");
                document.title = "Monetizer - Apps - Stats";
                $(".title-icon").attr("class", "title-icon fa fa-table");
                self.setCurrentAndWake(appsStatsView);
            });
        },

        dashboard: function() {
            var self = this;
            $.when(this.sleepCurrent()).then(function() {
                $(".page-title").html("Leagues");
                document.title = "Monetizer - Leagues";
                $(".title-icon").attr("class", "title-icon fa fa-dashboard");
                leaguesView.render();
                self.setCurrentAndWake(leaguesView);
            });
        },

        publicAdvertiserRequestDetails: function(id) {
            this.showRequestDetailsView(
                advertiserRequestDetailsView,
                id,
                "Traffic Request",
                "Advertiser's request for traffic",
                true
            );
        },

        advertiserRequestDetails: function(id) {
            this.showRequestDetailsView(
                advertiserRequestDetailsView,
                id,
                "Traffic Request",
                "Advertiser's request for traffic",
            );
        },

        publicPublisherRequestDetails: function(id) {
            this.showRequestDetailsView(
                publisherRequestDetailsView,
                id,
                "Offer Request",
                "Publisher's request for an offer",
                true
            );
        },

        publisherRequestDetails: function(id) {
            this.showRequestDetailsView(
                publisherRequestDetailsView,
                id,
                "Offer Request",
                "Publisher's request for an offer",
            );
        },

        showRequestDetailsView: function(view, id, docTitle, pageTitle, isPublic = false) {
            var type = isPublic ? 'public' : 'internal';
            var self = this;
            $.when(this.sleepCurrent())
                .then(function () {
                    document.title = "Monetizer - " + docTitle;
                    $(".page-title").html(pageTitle);
                    self.setCurrentAndWake(view);
                    view.showRequest(id, type);
                });
        },

        publicOfferMarket: function(queryString) {
            this.showOfferMarket(offerMarketView.public, "Traffic Requests", queryString);
        },

        offerMarket: function(queryString) {
            this.showOfferMarket(offerMarketView.internal, "Traffic Requests", queryString);
        },

        showOfferMarket: function(view, title, queryString) {
            if (this.currentView !== publisherRequestDetailsView && this.currentView !== advertiserRequestDetailsView) {
                view.reset();
            }
            var self = this;
            $.when(this.sleepCurrent())
                .then(function() {
                    $(".page-title").html(title);
                    document.title = "Monetizer - " + title;
                    self.setCurrentAndWake(view)
                });
        },

        requests: function() {
            var self = this;
            $.when(this.sleepCurrent()).then(function() {
                $(".page-title").html("My Requests");
                document.title = "Monetizer - Offer Requests - My Requests";
                $(".title-icon").attr("class", "title-icon fa fa-list");
                self.setCurrentAndWake(requestsView);
            });
        },

        liveLeads: function() {
            var self = this;
            $.when(this.sleepCurrent()).then(function() {
                $(".page-title").html("Live Leads");
                document.title = "Monetizer - Live Leads";
                $(".title-icon").attr("class", "title-icon fa fa-feed");
                liveLeadsView.render();
                self.setCurrentAndWake(liveLeadsView);
            });
        },

        inbox: function() {
            var self = this;
            $.when(this.sleepCurrent()).then(function() {
                $(".page-title").html("Inbox");
                document.title = "Monetizer - Inbox";
                $(".title-icon").attr("class", "title-icon fa fa-feed");
                inboxView.render();
                self.setCurrentAndWake(inboxView);
            });
        },


        banking: function(queryString) {
            var self = this;
            $.when(this.sleepCurrent()).then(function() {
                $(".page-title").html("Banking");
                document.title = "Monetizer - Banking";
                $(".title-icon").attr("class", "title-icon fa fa-vcard");
                bankingView.render(queryString);
                self.setCurrentAndWake(bankingView);
            });
        },

        domains: function() {
            var self = this;
            $.when(this.sleepCurrent()).then(function() {
                $(".page-title").html("Domains");
                document.title = "Monetizer - Domains";
                $(".title-icon").attr("class", "title-icon fa fa-link");
                domainsView.render();
                self.setCurrentAndWake(domainsView);
            });
        },

        domainParking: function () {
            var self = this;
            $.when(this.sleepCurrent()).then(function() {
                $(".page-title").html("Domain Parking");
                document.title = "Monetizer - Domain Parking";
                $(".title-icon").attr("class", "title-icon fa fa-parking");
                domainParkingView.render();
                self.setCurrentAndWake(domainParkingView);
            });
        },

        support: function() {
            var self = this;
            $.when(this.sleepCurrent()).then(function() {
                $(".page-title").html("Support");
                document.title = "Monetizer - Support";
                $(".title-icon").attr("class", "title-icon fa fa-question-circle");
                supportView.render();
                self.setCurrentAndWake(supportView);
            });
        },

        leaderboard: function() {
            var self = this;
            $.when(this.sleepCurrent()).then(function() {
                $(".page-title").html("Leaderboard");
                document.title = "Monetizer - Leaderboard";
                $(".title-icon").attr("class", "title-icon fa fa-question-circle");
                leaderboardView.render();
                self.setCurrentAndWake(leaderboardView);
            });
        },

        trends: function() {
            var self = this;
            $.when(this.sleepCurrent()).then(function() {
                $(".page-title").html("Trending");
                document.title = "Monetizer - Trending";
                $(".title-icon").attr("class", "title-icon fa fa-pie-chart");
                trendsView.render();
                self.setCurrentAndWake(trendsView);
            });
        },

        inspire: function() {
            var self = this;
            $.when(this.sleepCurrent()).then(function() {
                $(".page-title").html("Inspire");
                document.title = "Monetizer - Inspire";
                $(".title-icon").attr("class", "title-icon fa fa-lightbulb-o");
                inspireView.render();
                self.setCurrentAndWake(inspireView);
            });
        },

        profile: function() {
            var self = this;
            $.when(this.sleepCurrent()).then(function() {
                $(".page-title").html("Profile");
                document.title = "Monetizer - Profile";
                $(".title-icon").attr("class", "title-icon fa fa-cog");
                profileView.render();
                self.setCurrentAndWake(profileView);
            });
        },

        earnings: function() {
            var self = this;
            $.when(this.sleepCurrent()).then(function() {
                $(".page-title").html("Earnings");
                document.title = "Monetizer - Earnings";
                $(".title-icon").attr("class", "title-icon fa fa-money");
                earningsView.render();
                self.setCurrentAndWake(earningsView);
            });
        },

        links: function(subpage, var2) {
            var self = this;
            $.when(this.sleepCurrent()).then(function() {
                switch (subpage) {
                    case "edit":
                        $(".page-title").html("Edit Link");
                        document.title = "Monetizer - Edit Link";
                        $(".title-icon").attr("class", "title-icon fa fa-link");
                        linksView.showEditLink(subpage, var2);
                        break;
                    case "new":
                        $(".page-title").html("New Link");
                        document.title = "Monetizer - New Link";
                        $(".title-icon").attr("class", "title-icon fa fa-link");
                        linksView.showEditLink(subpage, var2);
                        break;
                    case "copy":
                        $(".page-title").html("Copy Link");
                        document.title = "Monetizer - Copy Link";
                        $(".title-icon").attr("class", "title-icon fa fa-link");
                        linksView.showEditLink(subpage, var2);
                        break;
                    default:
                        $(".page-title").html("Links");
                        document.title = "Monetizer - Links";
                        $(".title-icon").attr("class", "title-icon fa fa-link");
                        linksView.render();
                        break;
                }

                self.setCurrentAndWake(linksView);
            });
        },

        snapshot: function() {
            var self = this;
            $.when(this.sleepCurrent()).then(function() {
                $(".page-title").html("Snapshot");
                document.title = "Monetizer - Snapshot";
                $(".title-icon").attr("class", "title-icon fa fa-camera");
                homeView.showSnapshot();
                self.setCurrentAndWake(homeView);
            });
        },
        advertiserSubscriptions: function() {
            var self = this;
            $.when(this.sleepCurrent()).then(function() {
                $(".page-title").html("Advertiser Subscriptions");
                document.title = "Monetizer - Advertiser Subscriptions";
                $(".title-icon").attr("class", "title-icon fa fa-rss-square");
                advertiserSubscriptionsView.show();
                self.setCurrentAndWake(advertiserSubscriptionsView);
            })
        },
        subscriptions: function() {
            var self = this;
            $.when(this.sleepCurrent()).then(function() {
                $(".page-title").html("Offer Subscriptions");
                document.title = "Monetizer - Offer Subscriptions";
                $(".title-icon").attr("class", "title-icon fa fa-rss-square");
                subscriptionsView.showSubscriptions();
                self.setCurrentAndWake(subscriptionsView);
            })
        },
        marketplace: function() {
            var self = this;
            $.when(this.sleepCurrent()).then(function() {
                $(".page-title").html("Offer Marketplace");
                document.title = "Monetizer - Offer Marketplace";
                $(".title-icon").attr("class", "title-icon fa fa-bullseye");
                offerMarketplaceView.render();
                self.setCurrentAndWake(offerMarketplaceView);
            });
        },
        cpamarketplace: function() {
            var self = this;
            $.when(this.sleepCurrent()).then(function() {
                $(".page-title").html("Offer Marketplace");
                document.title = "Monetizer - Offer Marketplace";
                $(".title-icon").attr("class", "title-icon fa fa-bullseye");
                cpaMarketplaceView.render();
                self.setCurrentAndWake(cpaMarketplaceView);
            });
        },
        push: function() {
            var self = this;
            $.when(this.sleepCurrent()).then(function() {
                $(".page-title").html("Push Stats");
                document.title = "Monetizer - Push Stats";
                $(".title-icon").attr("class", "title-icon fa fa-bullseye");
                pushStatsView.render();
                self.setCurrentAndWake(pushStatsView);
            });
        },
        pushCollection: function() {
            var self = this;
            $.when(this.sleepCurrent()).then(function() {
                $(".page-title").html("Collect Push Subs");
                document.title = "Monetizer - Collect Push Subs";
                $(".title-icon").attr("class", "title-icon fa fa-bullseye");
                pushCollectionView.render();
                self.setCurrentAndWake(pushCollectionView);
            });
        },

        pidKeywordTool: function() {
            var self = this;
            $.when(this.sleepCurrent()).then(function() {
                $(".page-title").html("PID to Keyword Tool");
                document.title = "Monetizer - PID to Keyword Tool";
                $(".title-icon").attr("class", "title-icon fa fa-exchange");
                pidKeywordTool.render();
                self.setCurrentAndWake(pidKeywordTool);
            });
        },

        subsourceAnalysis: function() {
            var self = this;
            $.when(this.sleepCurrent()).then(function() {
                $(".page-title").html("Subsource (PID) Analysis");
                document.title = "Monetizer - Subsource (PID) Analysis";
                $(".title-icon").attr("class", "title-icon fa fa-info-circle");
                self.setCurrentAndWake(subsourceAnalysisView);
            });
        },

        academy: function (subpage, filter) {
            var self = this;
            $.when(this.sleepCurrent())
                .then(function(){
                    switch(subpage) {
                        case 'type':
                            $('.page-title').html('Academy');
                            document.title = 'Academy' + ' - '+filter.toUpperCase();
                            academyView.render(subpage,filter);
                            self.setCurrentAndWake(academyView);

                            break;
                        case 'post':
                            $('.page-title').html('Academy');
                            document.title = '';
                            academyPostView.render(filter);
                            self.setCurrentAndWake(academyPostView);

                            break;
                        case 'tag':
                            $('.page-title').html('Academy');
                            document.title = 'Academy' + ' - '+filter.toUpperCase();
                            academyView.render(subpage,filter);
                            self.setCurrentAndWake(academyView);

                            break;
                        case 'search':
                            $('.page-title').html('Academy');
                            document.title = 'Academy' + ' - '+filter.toUpperCase();
                            academyView.render(subpage,filter);
                            self.setCurrentAndWake(academyView);
                            break;
                        default:
                            $('.page-title').html('Academy');
                            document.title = 'Academy';
                            academyView.render(filter);
                            self.setCurrentAndWake(academyView);

                            break;

                    }
                });
        },

        unsubscribe: function (list, id, hash) {
            var self = this;
            $.when(this.sleepCurrent())
                .then(function(){
                    $('.page-title').html('Unsubscribe');
                    document.title = 'Unsubscribe';
                    unsubscribeView.render(list,id, hash);
                    self.setCurrentAndWake(unsubscribeView);
                });
        },

        terms: function () {
            var self = this;
            $.when(this.sleepCurrent())
                .then(function(){
                    $('.page-title').html('Terms of Service');
                    document.title = 'Terms of Service';
                    tosView.render();
                    self.setCurrentAndWake(tosView);
                });
        },


        login: function () {
            var self = this;
            $.when(this.sleepCurrent())
                .then(function(){
                    $('.page-title').html('Login');
                    document.title = 'Login';
                    loginView._showLogin(Backbone.history.getFragment());

                });
        },


        sleepCurrent: function() {
            var dfd = $.Deferred();

            if (this.currentView !== null) {
                $.when(
                    this.currentView.sleep(),
                    this.clearSubViews(),
                    this.currentView.remove(),
                    this.currentView.unbind(),
                    this.currentView.undelegateEvents(),
                    this.currentView.stopListening(),
                    this.currentView.$el.empty()
                ).then(function() {
                    dfd.resolve();
                });
            } else {
                dfd.resolve();
            }
            return dfd.promise();
        },

        clearSubViews: function() {
            var dfd = $.Deferred();
            if (this.currentView._subViews) {
                _.each(this.currentView._subViews, function(subView) {
                    subView.remove();
                    subView.unbind();
                });
                this.currentView._subViews = [];
                dfd.resolve();
            } else {
                dfd.resolve();
            }
            return dfd.promise();
        },

        setCurrentAndWake: function(view) {
            var hasPid = false;

            if(reloadOnRoute && window.location.hostname === 'app.monetizer.com') {
                window.location.reload(true);
            }


            this.currentView = view;
            this.currentView.wakeUp();
            $(window).scrollTop(0);
            $(".nav").removeClass("nav-show");
            $("body, html").removeClass("overflow-hidden");

            queryString = window.location.href.split("?");
            if (queryString[1] !== undefined) {
                queryString = queryString[1];

                params = queryString.split("&");

                _.each(params, function(param) {
                    if (param.substr(0, 3) === "pid") {
                        hasPid = true;
                    }
                });
            }

        },

    });

    return new MainRouter();
});
